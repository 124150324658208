import { Component, OnDestroy, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';

// rxjs
import { merge, Observable, of, Subscription, throwError } from 'rxjs';

// models
import {UserModel, UserConstants, UserRoles} from './../../models/user.model';
import { TeamConstants, TeamModel } from './../../models/team.model';

// providers
import { environment } from './../../environments/environment';
import { Utils } from './../../providers/utils.service';
import { LocalData } from './../../providers/local-data.service';
import { AuthService } from 'providers/auth.service';
import { Logger } from './../../providers/logger.service';
import { UserService } from './../../providers/user.service';
import { TeamService } from './../../providers/team.service';
import { SiteParameters } from './../../environments/site.default';
import { catchError, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { CoachSurveyModel, CoachSurveyStatus } from 'models/coach.model';
import { MessageService } from 'providers/message.service';
import { HubsService } from 'providers/hubs.service';
import { ChatService } from 'providers/chat.service';
import { ContactUsComponent } from 'app/shared/contact-us/contact-us.component';

declare const $: any;

// metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

// sidebar menu items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard', title: 'Dashboard', type: 'link', icontype: 'dashboard'
  }, {
    path: '/calendar', title: 'Calendar', type: 'link', icontype: 'date_range'
  }, {
    path: '/media', title: 'Photos & Videos', type: 'link', icontype: 'collections'
  }, {
    path: '/safekeeper', title: 'SafeKeeper', type: 'link', icontype: 'enhanced_encryption'
  }, {
    path: '/message', title: 'Messages_', type: 'link', icontype: 'message'
  }, {
    path: '/chat', title: 'Messages', type: 'link', icontype: 'message'
  }, {
    path: '/health', title: 'Health', type: 'link', icontype: 'local_hospital'
  }, {
    path: '/financial', title: 'Financial', type: 'link', icontype: 'account_balance'
  }, {
    path: '/coach', title: 'Dashboard', type: 'link', icontype: 'grid_view'
  }
];

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy {
  private source = 'SidebarComponent';
  public userConstants: UserConstants = new UserConstants();
  public teamConstants: TeamConstants = new TeamConstants();
  private user: UserModel;
  public menuItems: any[];
  public teams: Array<TeamModel> = new Array<TeamModel>();
  public notifications: number;
  public tickets: number;
  public defaultAvatarImg: string;
  public disableNav = false;
  public siteURLs = SiteParameters.URLS;
  public coach: UserModel;
  public currentTeamChangedSubscription: Subscription
  public currentTeamHasPendingCoachSurvey = false;
  public coachInfoLoading = false;
  public userUnreadCounter: any = 0;
  private hubConnection: signalR.HubConnection;

  constructor(
    private router: Router,
    private utils: Utils,
    private localData: LocalData,
    private authService: AuthService,
    private logger: Logger,
    public userService: UserService,
    private messageService: MessageService,
    private teamService: TeamService,
    private hubsService: HubsService,
    private chatService: ChatService,
    public dialog: MatDialog
  ) {
    this.currentTeamChangedSubscription = merge(
      of(this.localData.getCurrentTeamId()),
      this.localData.currentTeamChanged$
    )
    .pipe(distinctUntilChanged())
    .subscribe(teamId => {
      this.fetchCurrentTeamCoach(teamId);
    });
  }

  ngOnInit() {
    this.coachInfoLoading = true;
    this.defaultAvatarImg = environment.dashboardBaseUrl + this.userConstants.DEFAULT_AVATAR;
    this.menuItems = ROUTES.filter(menuItem => {
      return (
        menuItem.title === 'Calendar' || menuItem.title === 'Photos & Videos' || menuItem.title === 'SafeKeeper' || menuItem.title === 'Messages'
      );
    });

    this.logger.logInfo(this.source, 'ngOnInit', 'subscribe: source: getUserUnreadMessagesCounter');
    this.chatService.getUnreadCount()
      .subscribe(
        counter => {
          this.userUnreadCounter = counter;
        },
        err => {
          this.logger.logError(this.source, 'getUserUnreadMessagesCounter', err, 'API Error');
          this.userUnreadCounter = 0;
        }
      );

    this.userService.doesHaveRole(UserRoles.COACH)
      .subscribe(isCoach => {
        if (isCoach) {
          const coachDashboard = ROUTES.find(item => item.path === '/coach');
          this.menuItems = [coachDashboard, ...this.menuItems];
        }
      });

    // update this.user when user changes
    this.logger.logInfo(this.source, 'ngOnInit', 'subscribe: source: userSource');
    this.userService.userSource
      .subscribe((user) => {
        this.user = user;
        if (user && user.logLevelWeb) {
          this.logger.logLevels = JSON.parse(user.logLevelWeb);
        }
        this.logger.logTrace(this.source, 'ngOnInit', {userId: user?.id || ''}, 'source: userSource');
      });

    // update this.teams when teams changes
    this.logger.logInfo(this.source, 'ngOnInit', 'subscribe: source: userTeamsSource');
    this.teamService.userTeamsSource
      .subscribe((teams) => {
        if (!teams) {
          teams = this.localData.getTeams();
        }
        if (!teams) {
          teams = new Array<TeamModel>();
        }
        if (teams.length > 0) {
          teams.map(team => {
            if (!team.avatarUrl) {
              team.avatarUrl = this.defaultAvatarImg;
            }
          });
        }
        this.teams = teams;
        this.logger.logTrace(this.source, 'ngOnInit', {userId: this.user?.id || ''}, 'source: userTeamsSource');
      });
    if (localStorage.getItem('newSignup')) {
      const self = this;
      localStorage.removeItem('newSignup');
      setTimeout(function () {
        self.utils.doShowTutorialGetStarted.next(true);
      }, 500);
    }
    this.hubsService.startConnection('chathub',async (con) => {
      await con.invoke('ListenToNew',this.localData.getTeams().map(x => x.id))
      con.on('RefreshCounter',() => {
        this.refreshUnread()
      })
      this.hubConnection = con
    })
  }

  refreshUnread = () => {
    this.chatService.getUnreadCount().subscribe((c) => {
      this.userUnreadCounter = c
    })
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      const ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
    }
  }

  get doesHaveUserRole(): Observable<boolean> {
    return this.userService.doesHaveRole(UserRoles.USER);
  }

  get isBUCEnabled(): Observable<boolean> {
    return this.userService.isBUCEnabled()
  }

  get doesHaveCoachRole(): Observable<boolean> {
    return this.userService.doesHaveRole(UserRoles.COACH);
  }

  get currentTeam() {
    return this.teams.find(t => t.id === this.localData.getCurrentTeamId());
  }

  // utils
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  getHelp() {
    setTimeout(function () {
      window.open('https://support.elivelihood.com', '_blank');
    }, 500);
  }

  showTutorialGetStarted(val) {
    if (val) {
      this.utils.doShowTutorialGetStarted.next(val);
    }
  }

  showContactInfo() {
    this.teamService.tutorialClicked(this.localData.getCurrentTeamId()).subscribe(() => {})
    this.dialog.open(ContactUsComponent, {
      width: '687px',
    });
  }

  async openSurvey() {
    const baseUrl = environment.surveyBaseUrl;
    let params = new HttpParams();
    const user = this.localData.getUser();
    params = params.append('userId', user.id);
    params = params.append('userEmail', encodeURI(user.email));
    params = params.append('employeeEmail', encodeURI(user.originalInvitedByEmail || ''));
    params = params.append('clientId', user.clientId || '');
    const coachName = `${this.coach?.firstName || ''} ${this.coach?.lastName || ''}`.trim();
    params = params.append('coachName', encodeURI(coachName));
    if (user.clientId) {
      const clientName = await this.userService.getUserClientName().toPromise()
      params = params.append('clientName', encodeURI(clientName.clientName));
    }
    const url = `${baseUrl}?${params.toString()}`;
    window.open(url, '_blank');
  }

  logout(event) {
    event.preventDefault()
    this.authService.signOut()
      .subscribe(() => {
        this.router.navigate(['login']);
      })
  }

  fetchCurrentTeamCoach(teamId) {
    if(!teamId) return;
    this.coachInfoLoading = true;
    this.currentTeamHasPendingCoachSurvey = false;
    this.coach = null;
    this.teamService.getTeamCoachSurvey(teamId)
      .pipe(
        catchError(err => {
          if(err.status === 404) {
            return of(null);
          }
          throwError(err);
        }),
        mergeMap((survey: CoachSurveyModel) => {
          if (!survey) {
            this.coachInfoLoading = false;
            throw "no-request";
          }
          else if (survey.coachId && survey.status === CoachSurveyStatus.ACCEPTED) {
            return this.teamService.getTeamCoach(teamId);
          }
          else {
            this.currentTeamHasPendingCoachSurvey = true;
            this.coachInfoLoading = false;
            throw "coach-not-responded";
          }
        }),
      )
      .subscribe(coach => {
        this.coach = coach;
        this.coachInfoLoading = false;
      },
      err => {
        this.coachInfoLoading = false;
      });
  }

  private closeConnection = async () => {
    this.hubConnection.invoke('RemoveFromNew',this.localData.getTeams().map(x => x.id)).then(() => {
      this.hubConnection.stop();
    })
  }

  async ngOnDestroy() {
    this.currentTeamChangedSubscription.unsubscribe();
    await this.closeConnection();
  }
}
