<nav #navbar class="navbar navbar-transparent navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-minimize">
      <button id="minimizeSidebar" class="btn btn-round btn-white btn-fill btn-just-icon">
        <i class="material-icons visible-on-sidebar-regular">more_vert</i>
        <i class="material-icons visible-on-sidebar-mini">view_list</i>
      </button>
    </div>
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" (click)="sidebarToggle()">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <div class="navbar-left">
        <span class="navbar-brand navbar-brand-header">{{ pageTitle | async }}</span>
        <a [routerLink]="[]" (click)="openSurvey()">
          <i class="material-icons">poll</i>
          <p>Online Survey</p>
        </a>
      </div>
    </div>

    <!-- collapses into right side sidebar component -->
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="hasTutorial">
          <button mat-button color="accent" (click)="showTutorial()">
            {{ tutorialTitle }} Tutorial
          </button>
        </li>
        <li class="dropdown">
          <a href="#" title="User" class="dropdown-toggle" data-toggle="dropdown">
            <i class="material-icons">person</i>
            <p class="hidden-lg hidden-md">Profile</p>
          </a>
          <ul class="dropdown-menu dropdown-menu-dark">
            <li>
              <a [routerLink]="['/user']">Profile</a>
            </li>
            <li class="divider"></li>
            <li>
              <a href="#" (click)="logout($event)">Logout</a>
            </li>
          </ul>
        </li>
        <li class="separator hidden-lg hidden-md"></li>
      </ul>
    </div>
  </div>
</nav>

  <!-- BEGIN Tutorial modal -->
  <div class="modal fade" id="tutorialModal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notice tutorialModal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel">{{ pageTitle }} Tutorial</h5>
        </div>
        <div *ngIf="!hideTutorialVideo" class="modal-body text-center">
          <div [ngSwitch]="tutorialTitle">
            <ng-template [ngSwitchCase]="'Overview'">
                <iframe src="https://player.vimeo.com/video/964639249?h=ccb7e772e4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0" class="tutorialVideo" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </ng-template>
            <ng-template [ngSwitchCase]="'Messages'">
                <iframe src="https://player.vimeo.com/video/964639164?h=781eba1377&title=0" class="tutorialVideo" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </ng-template>
            <ng-template [ngSwitchCase]="'Calendar'">
                <iframe src="https://player.vimeo.com/video/964639130?h=bb56572af4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0" class="tutorialVideo" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </ng-template>
          </div>
        </div>
        <div class="modal-footer text-center">
          <div class="row">
            <div class="col-sm-12 text-center">
              <button type="button" class="btn btn-default btn-round" data-dismiss="modal" (click)="closeTutorialVideo()">
                <i class="material-icons">close</i> Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END Tutorial modal -->
