<nav class="navbar navbar-fixed-top">
  <div class="container">
    <div class="navbar-header">
      <div class="login-brand">
        <div class="logo">
          <div class="logo-normal">
            <a
              [href]="landingPageUrl"
              class="simple-text simple-text-title"
              target="_blank"
            >
              <img
                class="word-mark"
                src="./assets/img/logo_BH-1-170-white.png"
                alt="Bright Horizons"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
