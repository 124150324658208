import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { SiteParameters } from 'environments/site.default';

@Component({
  selector: 'app-header',
  templateUrl: `./header.component${SiteParameters.SITE_PREFIX}.html`,
  styleUrls: [`./header.component${SiteParameters.SITE_PREFIX}.scss`],
})
export class HeaderComponent implements OnInit {
  public landingPageUrl = environment.landingPageUrl;
  constructor() {}

  ngOnInit(): void {}
}
