<div class="logo logo-bh">
  <div class="logo-normal">
    <a
      href="https://www.brighthorizons.com"
      class="simple-text simple-text-title text"
      target="_blank"
    >
      <img
        class="el-word-mark-bh"
        src="./assets/img/logo_BH-1-170-white.png"
        alt="eLivelihood"
      />
    </a>
  </div>
</div>

<div class="sidebar-wrapper sidebar-wrapper-bh">
  <!-- if window < 992 -->
  <div *ngIf="isMobileMenu()">
    <ul class="nav nav-mobile-menu">
      <li class="dropdown">
        <a
          class="dropdown-toggle"
          data-toggle="dropdown"
          href="#"
          aria-expanded="false"
        >
          <i class="material-icons">person</i>
          <p class="hidden-lg hidden-md">
            <span>User</span>
            <b class="caret"></b>
          </p>
          <div class="ripple-container"></div>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a [routerLink]="['/user']">Profile</a>
          </li>
          <li>
            <a href="#" (click)="logout($event)">Logout</a>
          </li>
        </ul>
      </li>
      <li>
        <a href="https://support.elivelihood.com" target="_blank">
          <i class="material-icons">help</i>
          <p class="hidden-lg hidden-md">Help</p>
        </a>
      </li>
      <li class="separator hidden-lg hidden-md"></li>
    </ul>
  </div>

  <app-teams-list
    *ngIf="(doesHaveCoachRole | async) && currentTeam"
    [hideNewTeamButton]="true"
    [teams]="[currentTeam]"
  ></app-teams-list>
  <app-teams-list
    *ngIf="doesHaveUserRole | async"
    [teams]="teams"
  ></app-teams-list>

  <!-- menu -->
  <div class="nav-container" style="flex-grow: 1">
    <ul class="nav">
      <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
        <!-- single link-->
        <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
          <i class="material-icons">{{ menuitem.icontype }}</i>
          <div
            *ngIf="menuitem.title === 'Messages' && userUnreadCounter > 0"
            class="new-pill"
          >
            New
          </div>
          <p>{{ menuitem.title }}</p>
        </a>

        <!-- submenu
        <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()">
          <i class="material-icons">{{menuitem.icontype}}</i>
          <p>{{menuitem.title}}
            <b class="caret"></b>
          </p>
        </a> -->

        <!-- submenu items
        <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
          <ul class="nav">
            <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
              <a [routerLink]="[menuitem.path, childitem.path]">
                <span class="sidebar-mini">{{childitem.ab}}</span>
                <span class="sidebar-normal">{{childitem.title}}</span>
              </a>
            </li>
          </ul>
        </div> -->
      </li>
      <ng-container *ngIf="doesHaveUserRole | async">
        <li *ngIf="coachInfoLoading">
          <a disabled>
            <i class="material-icons">hourglass_empty</i>
            <p>Loading...</p>
          </a>
        </li>
        <li
          *ngIf="
            !coachInfoLoading && !coach && !currentTeamHasPendingCoachSurvey
          "
          routerLinkActive="active"
        >
          <a [routerLink]="['/get-coach']">
            <i class="material-icons">support_agent</i>
            <p>Add A Coach</p>
          </a>
        </li>
        <li
          *ngIf="
            !coachInfoLoading && !coach && currentTeamHasPendingCoachSurvey
          "
          routerLinkActive="active"
        >
          <a [routerLink]="['/coach-info', 'pending']">
            <i class="material-icons">support_agent</i>
            <p>Coach Request Pending</p>
          </a>
        </li>
        <li *ngIf="!coachInfoLoading && coach">
          <a [routerLink]="['/coach-info', coach.id]" class="coach-link">
            <img
              class="img-circle coach-photo"
              [src]="coach.avatarUrl || defaultAvatarImg"
            />
            <p>Coach {{ coach.displayName }}</p>
          </a>
        </li>
      </ng-container>
      <li routerLinkActive="active">
        <a
          [routerLink]="['medicare-concierge']"
          class="coach-link"
        >
          <img
            class="coach-photo"
            src="../../assets/img/medicare-concierge.svg"
          />
          <p>Medicare Concierge</p>
        </a>
      </li>
      <li>
        <a
          [attr.href]="siteURLs.BH_RESOURCE_LIBRARY_URL"
          class="coach-link"
          target="_blank"
        >
          <img
            class="img-circle coach-photo"
            src="../../assets/img/resource-library.png"
          />
          <p>Resource Library</p>
        </a>
      </li>
      <li>
        <a [routerLink]="[]" (click)="showContactInfo()">
          <i class="material-icons">help</i>
          <p>Need Help?</p>
        </a>
      </li>
      <li>
        <a [routerLink]="[]" (click)="openSurvey()">
          <i class="material-icons">poll</i>
          <p>Tell us How We’re Doing</p>
        </a>
      </li>
    </ul>
  </div>

  <div
    *ngIf="doesHaveUserRole && isBUCEnabled | async"
    style="margin-bottom: 10px"
  >
    <div class="info">
      <div>
        <ul class="nav">
          <ng-container>
            <li>
              <a [attr.href]="siteURLs.BH_BACKUP_URL" target="_blank">
                <button class="btn nav-button">Schedule Back-Up Care</button>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
